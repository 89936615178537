import styled from 'styled-components/macro'

export const BannerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #e8f6f9;
  padding: 16px;
  border-radius: 20px;
  max-width: 600px;
  margin-top: 20px;
`

export const IconWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const TextContainer = styled.div`
  color: #333;
  font-size: 14px;
  line-height: 1.6;

  p {
    margin: 0;
  }
`

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
`
