import styled from 'styled-components/macro'
import * as React from 'react'

export const CloseIcon = ({ alt }: { alt?: string }) => (
  <img src="/img/close.svg" width="19" height="19" alt={alt} />
)

export const DownloadIcon = ({ alt }: { alt?: string }) => (
  <img src="/img/download.png" width="29" height="29" alt={alt} />
)

export const FileIcon = ({ alt }: { alt?: string }) => <img src="/img/file.svg" alt={alt} />

export const File2Icon = ({ alt }: { alt?: string }) => <img src="/img/file2.svg" alt={alt} />

export const ClockIcon = styled.img.attrs({ src: '/img/clock.svg', alt: '' })`
  vertical-align: text-bottom;
`

export const ChevronRight = styled.img.attrs({ src: '/img/chevron.svg', alt: '' })`
  transform: rotate(270deg);
  width: 10px;
  height: 10px;
`

export const ChevronLeft = styled.img.attrs({ src: '/img/chevron.svg', alt: '' })`
  transform: rotate(90deg);
  width: 10px;
  height: 10px;
`

export const ChevronUp = styled.img.attrs({ src: '/img/chevron.svg', alt: '' })`
  transform: rotate(180deg);
  width: 19px;
  height: 19px;
`

export const ChevronDown = styled.img.attrs({ src: '/img/chevron.svg', alt: '' })`
  width: 19px;
  height: 19px;
`

export const DoneIcon = ({ alt }: { alt?: string }) => (
  <img src="/img/icon-done.png" width="32" height="32" alt={alt} />
)

export const BigSuccessIcon = ({ alt }: { alt?: string }) => (
  <img src="/img/icon-success.svg" alt={alt} />
)

export const BigWarningIcon = ({ alt }: { alt?: string }) => (
  <img src="/img/icon-warning.svg" alt={alt} />
)

export const BigErrorIcon = ({ alt }: { alt?: string }) => (
  <img src="/img/icon-error.svg" alt={alt} />
)

export const CallIcon = styled.img.attrs({ src: '/img/phone.svg', alt: 'Soita' })`
  width: 14px;
  height: 14px;
  vertical-align: text-top;
`

export const BigInfoIcon = ({ alt }: { alt?: string }) => <img src="/img/icon-info.svg" alt={alt} />

export const LockedIcon = ({ alt }: { alt?: string }) => <img src="/img/locked.svg" alt={alt} />

export const EyeIcon = ({ alt }: { alt?: string }) => <img src="/img/eye.svg" alt={alt} />

export const InformationIcon = ({ alt }: { alt?: string }) => (
  <img src="/img/information.svg" alt={alt} />
)

export const InformationBorderlessIcon = ({ alt }: { alt?: string }) => (
  <img src="/img/information_without_border.svg" alt={alt} />
)
