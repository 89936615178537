import React from 'react'
import { useTranslation } from 'react-i18next'
import { BannerContainer, IconWrapper, TextContainer, Title } from './InfoBanner.styles'
import { InformationBorderlessIcon } from '../common/icons'

interface InfoBannerProps {
  title?: string
  content: string
}

const InfoBanner: React.FC<InfoBannerProps> = ({ title, content }) => {
  const { t } = useTranslation()
  const bannerContent = t(content)

  return (
    <BannerContainer>
      <IconWrapper>
        <InformationBorderlessIcon />
      </IconWrapper>
      <TextContainer>
        {title && <Title>{t(title)}</Title>}
        <p dangerouslySetInnerHTML={{ __html: bannerContent }} />
      </TextContainer>
    </BannerContainer>
  )
}

export default InfoBanner
